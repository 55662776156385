import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import dynamic from "next/dynamic";

const Footer = () => {
  const Layout = dynamic(() => import("../components/layout"), { ssr: true });

  return (
    <footer className="bg-white dark:bg-jacarta-900" id="site-footer">
      <div className="container mx-auto px-6 py-12" id="footer-container">
        {/* Top Section */}
        <div className="flex flex-col md:flex-row md:justify-between" id="footer-top-section">
          {/* Logo and Description */}
          <div className="mb-8 md:mb-0 md:w-1/3" id="footer-brand">
            <Link href="#" className="block">
              <img src="/images/logo.png" className="h-8 dark:hidden" alt="mtion.xyz | live streaming platform" />
              <img src="/images/logo_white_opt.png" className="h-8 hidden dark:block" alt="mtion.xyz | live streaming platform" />
            </Link>
            <p className="mt-4 text-gray-600 dark:text-jacarta-300">Uniting the world through play</p>
            {/* Social Icons */}
            <div className="flex mt-6 space-x-4" id="social-icons">
              {socialIcons.map((item) => (
                <Link href={item.href} key={`Link-menu-item-${item.id}`} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-accent dark:text-jacarta-300 dark:hover:text-white">
                  <svg className="h-5 w-5 fill-current" aria-hidden="true">
                    <use xlinkHref={`/icons.svg#icon-${item.text}`}></use>
                  </svg>
                </Link>
              ))}
            </div>
          </div>
          {/* Footer Menu */}
          <div className="grid grid-cols-2 gap-8 md:w-2/3 md:grid-cols-4" id="footer-menus">
            {footerMenuList.map((single) => (
              <div key={single.id} id={`footer-menu-${single.id}`}>
                <h3 className="text-sm font-semibold text-gray-700 dark:text-white">{single.title}</h3>
                <ul className="mt-4 space-y-2" id={`footer-menu-list-${single.id}`}>
                  {single.list.map((item) => (
                    <li key={`footer-menu-item-${item.id}`} id={`footer-menu-item-${item.id}`}>
                      <Link href={item.href} className="text-gray-600 hover:text-accent dark:text-jacarta-300 dark:hover:text-white">
                        {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="mt-12 border-t border-gray-200 dark:border-jacarta-700 pt-8" id="footer-bottom-section">
          <div className="flex flex-col md:flex-row md:justify-between" id="footer-bottom-content">
            <p className="text-sm text-gray-600 dark:text-jacarta-400">© {new Date().getFullYear()} mtion interactive inc. — Made with ❤️ in 🍁</p>
            <ul className="mt-4 flex space-x-4 text-sm text-gray-600 dark:text-jacarta-400 md:mt-0" id="legal-links">
              <li id="terms-link">
                <Link href="/legal" className="hover:text-accent dark:hover:text-white">
                  Terms and Conditions
                </Link>
              </li>
              <li id="privacy-link">
                <Link href="/privacy" className="hover:text-accent dark:hover:text-white">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
