import Footer from "./footer";
import { useRouter } from "next/router";
import Header from "./header/Header";
import { Analytics } from '@vercel/analytics/next';

export default function Layout({ children }) {
    const route = useRouter();

    return (
        <>
            <Header />
            <main>
                {children}
                <Analytics />
            </main>
            <Footer />
        </>
    );
}
