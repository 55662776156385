import Image from "next/image";
import Link from "next/link";
import Logo from "./../../public/images/logo.png";
import WhiteLogo from "./../../public/images/logo_white_opt.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function Header() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);

  // window resize with proper cleanup
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const route = useRouter();

  // Consolidated navigation data
  const navItems = [
    {
      id: 1,
      name: "Home",
      path: "/",
      external: false,
    },
    {
      id: 2,
      name: "Studio App",
      label: "Studio App",
      path: "https://store.steampowered.com/app/1920720?utm_source=website&utm_campaign=front_page",
      external: true,
    },
    {
      id: 3,
      name: "SDK",
      path: "https://github.com/mtion-tv/MtionSDK-StarterProject",
      external: true,
    },
    {
      id: 4,
      name: "Blog",
      path: "https://blog.mtion.xyz",
      external: true,
    },
    {
      id: 5,
      name: "About",
      path: "/about",
      external: false,
    },
  ];

  // Resource items if needed later
  const resourceItems = [
    {
      id: uuidv4(),
      name: "Discord",
      path: "https://discord.com/invite/CuRyapecDq",
    },
    {
      id: uuidv4(),
      name: "SDK",
      path: "https://github.com/mtion-tv/MtionSDK-StarterProject",
    },
    {
      id: uuidv4(),
      name: "Documentation",
      path: "https://docs.mtion.xyz/mtion/getting-started/overview",
    },
  ];

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu start*/}
      <header id="header" className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div className="flex items-center px-6 py-6 xl:px-24 ">
          <Link className="shrink-0" href="/">
            <div className="dark:hidden">
              <Image src={Logo} height={60} width={60} alt="mtion.xyz | Live streaming for streamers and fans" className="max-h-7 h-auto" />
            </div>
            <div className="hidden dark:block">
              <Image src={WhiteLogo} height={60} width={60} alt="mtion.xyz | Live streaming for the next generation" />
            </div>
          </Link>
          {/* End logo */}

          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* Dynamically render nav items */}
                {navItems.map((item) => (
                  <li key={item.id} className="js-nav-dropdown group relative">
                    <Link href={item.path}>
                      <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full" onClick={() => (item.external ? null : setToggle(false))}>
                        <span className={route.asPath === item.path ? "text-accent dark:text-accent" : ""}>
                          {item.label || item.name}
                          {item.id === 2 && <span className="text-sm text-jacarta-300">&nbsp;(Pre-Alpha)</span>}
                        </span>
                        <i className="lg:hidden">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} className="h-4 w-4 dark:fill-white">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        </i>
                      </button>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            {/* End menu for desktop */}
          </div>
          {/* header menu content end for desktop */}

          {/* Mobile toggle button */}
          <div className="ml-auto flex lg:hidden">
            <button className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]" aria-label="open mobile menu" onClick={() => setToggle(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
        </div>
      </header>
      {/* main desktop menu end */}

      {/* Mobile menu with improved accessibility */}
      <div className={`lg:hidden fixed inset-0 z-30 bg-white dark:bg-jacarta-800 transform transition-transform duration-300 ease-in-out ${toggle ? "translate-x-0" : "translate-x-full"}`} aria-hidden={!toggle} role="dialog" aria-modal={toggle}>
        {/* Header */}
        <div className="sticky top-0 z-10 flex items-center justify-between bg-white dark:bg-jacarta-800 p-4 border-b border-gray-200 dark:border-jacarta-600">
          <Link href="/" onClick={() => setToggle(false)}>
            <div className="dark:hidden">
              <Image src={Logo} height={40} width={40} alt="mtion.xyz" className="h-auto" priority />
            </div>
            <div className="hidden dark:block">
              <Image src={WhiteLogo} height={40} width={40} alt="mtion.xyz" className="h-auto" priority />
            </div>
          </Link>

          <button className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-jacarta-600 transition-colors" onClick={() => setToggle(false)} aria-label="Close menu">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Menu items - now consistent with desktop */}
        <nav className="h-[calc(100vh-4rem)] overflow-y-auto">
          <ul className="px-4 py-6 space-y-2">
            {navItems.map((item) => (
              <li key={item.id}>
                <Link href={item.path} className={`block py-3 px-4 rounded-lg hover:bg-gray-100 dark:hover:bg-jacarta-600 transition-colors ${route.asPath === item.path ? "bg-gray-100 dark:bg-jacarta-600" : ""}`} onClick={() => setToggle(false)}>
                  <span className={`text-base ${route.asPath === item.path ? "text-accent dark:text-accent" : "text-gray-900 dark:text-white"}`}>
                    {item.name}
                    {item.id === 2 && <span className="text-sm text-jacarta-300 ml-1">(Pre-Alpha)</span>}
                  </span>
                </Link>
              </li>
            ))}

            {/* Add resource items if needed */}
          </ul>
        </nav>
      </div>
    </>
  );
}
